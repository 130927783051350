import Header from './header/Header';
import Topbar from './topbar/Topbar';

const Index = () => {
    return(
        <>
            <Header />

            <Topbar/>
        </>
    );
}

export default Index;