import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { detAll, getAll } from "../../services/articlesService";

import "./Gallery.css";

const Gallery = () => {
  const [images, setImages] = useState(null);
  useEffect(async () => {
    const imgs = await getAll();
    setImages(imgs.result.items);
  }, []);
  return (
    <>
      <Container>
        {images?.map((item) => (
          <Row key={item.id}>
            <Col className="gallery" key={item.id}>
              <img key={item.id} src={item?.imageUrl} alt={item?.title} />
              <div>
                <h5>{item?.title}</h5>
                {/* <p>Additional description or caption</p> */}
              </div>
            </Col>
          </Row>
        ))}
        ;
      </Container>
    </>
  );
};

export default Gallery;
