import axios from "axios";
import AppConsts from "../services/appconst";

const qs = require("qs");

var abp = window.abp;

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  function (config) {
    if (!!abp.auth.getToken()) {
      config.headers.common["Authorization"] = "Bearer " + abp.auth.getToken();
    }

    config.headers.common[".AspNetCore.Culture"] = abp.utils.getCookieValue(
      "Abp.Localization.CultureName"
    );
    config.headers.common["Abp.TenantId"] =
      abp.multiTenancy.getTenantIdCookie();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      !!error.response &&
      !!error.response.data.error &&
      !!error.response.data.error.message &&
      error.response.data.error.details
    ) {
      // show error
    } else if (
      !!error.response &&
      !!error.response.data.error &&
      !!error.response.data.error.message
    ) {
      // login failed error
    } else if (!error.response) {
      // unknown error
    }

    return Promise.reject(error);
  }
);

const getFormData = (dataInput, useDefaultName = false, parentKey = "") => {
  const formData = new FormData();

  for (const prop in dataInput) {
    if (dataInput[prop] === null || dataInput[prop] === undefined) {
      continue;
    }

    const propName = prop.toLocaleLowerCase();
    const key = parentKey ? `${parentKey}.${prop}` : prop;


    if (Array.isArray(dataInput[prop])) {
      dataInput[prop].forEach((arrayValue, index) => {
        const arrayKey = `${key}`;
        if (typeof arrayValue === "object" && !(arrayValue instanceof File)) {
          const nestedFormData = getFormData(
            arrayValue,
            useDefaultName,
            arrayKey
          );
          for (const [nestedKey, nestedValue] of nestedFormData.entries()) {
            formData.append(nestedKey, nestedValue);
          }
        } else {
          formData.append(arrayKey, arrayValue);
        }
      });
    } else if (
      typeof dataInput[prop] === "object" &&
      !(dataInput[prop] instanceof File) &&
      !(dataInput[prop]?.image ? false : dataInput[prop]?.image instanceof Blob ? true : false)
    ) {
      const nestedFormData = getFormData(dataInput[prop], useDefaultName, key);
      for (const [nestedKey, nestedValue] of nestedFormData.entries()) {
        formData.append(nestedKey, nestedValue);
      }
    } else if (
      propName.includes("image") ||
      propName === "file" ||
      propName.endsWith("image") ||
      propName.endsWith("video")
    ) {
      const newName = !useDefaultName
        ? propName === "file"
          ? "file"
          : `file${prop}`
        : prop;
      const imageKey = parentKey ? `${parentKey}.${newName}` : newName;
      if (dataInput[prop]?.name && dataInput[prop]?.image) {
        formData.append(
          imageKey,
          dataInput[prop]?.image,
          dataInput[prop]?.name + ".jpg"
        );
      } else {
        formData.append(imageKey, dataInput[prop]);
      }
    } else {
      formData.append(key, dataInput[prop]);
    }
  }

  return formData;
};

export { getFormData };

export default http;
