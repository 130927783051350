import { Carousel } from "react-bootstrap";
import "./Topbar.css";
import images from "../../shared/canvas/images.json";

const Topbar = () => {
  return (
    <Carousel fade>
      {images.map((item) => (
        <Carousel.Item className="carousel" key={item.id}>
          <img className={item.className} src={item.img} alt={item.alt} />
          <Carousel.Caption>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
      {/* <img
      className="d-block w-100 h-auto"
      src="https://atcloud.uidevices.eu/index.php/apps/files_sharing/ajax/publicpreview.php?x=1920&y=465&a=true&file=20093-(4)_big-350x350.jpg&t=uALdjMEbMNfAoEm&scalingup=0"
      alt="First slide"
    />
    <Carousel.Caption>
      <h3>Дърворезби - купа</h3>
      <p>описание кратко.</p>
    </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <img
      className="d-block w-100 h-auto"
      src="https://atcloud.uidevices.eu/index.php/apps/files_sharing/ajax/publicpreview.php?x=1920&y=465&a=true&file=cups.bmp&t=3wVQkhsWHz4cXeL&scalingup=0"
      alt="Second slide"
    />

    <Carousel.Caption>
      <h3>Дърворезби - чаши</h3>
      <p>друго описание.</p>
    </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <img
      className="d-block w-100 h-auto"
      src="https://atcloud.uidevices.eu/index.php/apps/files_sharing/ajax/publicpreview.php?x=1920&y=393&a=true&file=masa.jpg&t=1jbSP5Jxm0RWrGE&scalingup=0"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>Дърворезби - маса</h3>
      <p>Хубава изработка.</p>
    </Carousel.Caption>
  </Carousel.Item> */}
    </Carousel>
  );
};

export default Topbar;
